import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { breakpoints, colors, fontFamily } from "../../../theme";
import {
  FiFacebook,
  FiGithub,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
  FiMail,
} from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { Element } from "react-scroll";
import SplashText from "../../components/SplashText";
import IconButton from "../../components/form/IconButton";
import useMediaQuery from "../../hooks/use-media-query";
import { BsChevronRight } from "react-icons/bs";
import Button from "../../components/form/Button";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { links } from "../../../config";

// data
const socialLinks = [
  {
    name: "email",
    icon: <FiMail size="24px" color="#666" />,
    link: links.email,
  },
  {
    name: "twitter",
    icon: <FiTwitter size="24px" color="#666" />,
    link: links.twitter,
  },

  {
    name: "linkedin",
    icon: <FiLinkedin size="24px" color="#666" />,
    link: links.linkedin,
  },

  {
    name: "instagram",
    icon: <FiInstagram size="24px" color="#666" />,
    link: links.instagram,
  },

  {
    name: "github",
    icon: <FiGithub size="24px" color="#666" />,
    link: links.github,
  },
  {
    name: "facebook",
    icon: <FiFacebook size="24px" color="#666" />,
    link: links.facebook,
  },
  {
    name: "whatsapp",
    icon: <IoLogoWhatsapp size="24px" color="#666" />,
    link: links.whatsapp,
  },
];

// styles

const Heading = styled.h1`
  font-family: ${fontFamily.extraBold};
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -1%;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 52px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 60px;
  }
`;
const Section = styled(Element)`
  // height: 100vh;
  padding: 20px 0px 60px;
  background-color: ${props => (props.bgColor ? props.bgColor : "#ffffff")};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 70px 0 150px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: calc(100% - 18px);
  margin: 36px 9px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 50px 9px;
    // width: calc(100% - 18px);
    // margin: 0 9px;
    display: block;
    width: 650px;
    margin: 120px auto 10px;
    line-height: 160%;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    width: 820px;
    line-height: 240%;
  }
  @media only screen and (min-width: 1130px) {
    width: 940px;
    line-height: 280%;
  }

  @media only screen and (min-width: 1360px) {
    width: 1060px;
    line-height: 340%;
  }
`;
const FormText = styled.span`
  font-size: 16px;
  font-family: ${fontFamily.medium};
  margin: 24px 0 6px;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 14px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1130px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1360px) {
    font-size: 24px;
  }
`;

const FormInput = styled.input`
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  font-size: 16px;

  &:focus {
    border-bottom: 1px solid #fb5d09;
  }
`;
const FormSelect = styled.select`
  outline: none;
  border: none;
  border-bottom: 1px solid #000;
  font-size: 16px;
  background: transparent;

  &:focus {
    border-bottom: 1px solid #fb5d09;
  }
`;

const NameInput = styled(FormInput)`
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 12px;
    width: 140px;
    font-size: 14px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 18px;
    width: 190px;
  }
  @media only screen and (min-width: 1130px) {
    font-size: 20px;
    width: 230px;
  }
  @media only screen and (min-width: 1360px) {
    font-size: 24px;
    width: 250px;
  }
`;

const StyledButton = styled(Button)`
  // height: 50px;
  // width: 156px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: ${colors.grey.medium};
    color: ${colors.grey.darkMedium};
    border: none;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 24px;
    padding: 21px 39px;
    margin: 60px auto;
  }
`;

const ContactUsButton = styled(StyledButton)`
  @media only screen and (min-width: ${breakpoints.desktop}) {
    margin-left: 0;
  }
`;

const ServiceSelect = styled(FormSelect)`
  margin-right: 0;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 12px;
    width: 220px;
    font-size: 14px;
    // min-width: 200px;
    // min-width: 140px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 18px;
    width: 280px;
  }
  @media only screen and (min-width: 1130px) {
    font-size: 20px;
    width: 310px;
  }
  @media only screen and (min-width: 1360px) {
    font-size: 24px;
    width: 350px;
  }
`;

const EmailInput = styled(FormInput)`
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 12px;
    width: 190px;
    font-size: 14px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 18px;
    width: 250px;
  }
  @media only screen and (min-width: 1130px) {
    font-size: 20px;
    width: 310px;
  }
  @media only screen and (min-width: 1360px) {
    font-size: 24px;
    width: 370px;
  }
`;

const ContactSectionFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  // margin-right: -40px;
  margin-top: 160px;
`;
const ContactSectionFooterText = styled.span`
  font-size: 14px;
  line-height: 200%;
  @media only screen and (min-width: 1130px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1360px) {
    font-size: 18px;
  }
`;
const SocialIcons = styled.div`
  display: flex;
`;

const ContactMeHeading = styled(Heading)`
  width: 80%;
  margin: 0.67em auto;

  @media only screen and (min-width: ${breakpoints.desktop}) {
    width: 70%;
  }

  @media only screen and (min-width: 1280px) {
    width: 60%;
  }
  @media only screen and (min-width: 1500px) {
    width: 55%;
  }
  @media only screen and (min-width: 1600px) {
    width: 50%;
  }

  @media only screen and (min-width: 1800px) {
    width: 40%;
  }
  @media only screen and (min-width: 2250px) {
    width: 35%;
  }
  @media only screen and (min-width: 2580px) {
    width: 30%;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: currentcolor;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

const AsyncButton = ({ state, children, ...props }) => {
  // state: "" | "success" | "error"

  return (
    <ContactUsButton {...props}>
      {state === "loading" && <>Loading...</>}
      {state === "success" && <>Success...</>}
      {state === "error" && <>Error...</>}
      {!state && (
        <>
          {children}
          <BsChevronRight />{" "}
        </>
      )}
    </ContactUsButton>
  );
};

const ContactMeSection = () => {
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.tablet} )`);
  const [formStatus, setFormStatus] = useState("");

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    // console.log({ formStatus });
    if (formStatus === "success" || formStatus === "error") {
      setTimeout(function () {
        setFormStatus("");
        reset({});
      }, 1500);
    }
  }, [formStatus, reset]);

  // const canSubmit = true;

  // const canSubmit = !!formData.name && !!formData.service && !!formData.email;
  // console.log({ canSubmit });

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  const handleFormSubmit = data => {
    // console.log({ data, isValid });
    // set state to loading

    if (isValid) {
      setFormStatus("loading");

      // Call API Request
      toast.promise(
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...data }),
        }),
        {
          loading: "Saving...",
          success: response => {
            // console.log({ response });
            setFormStatus("success");
            return <b>Thanks, Looking forward to work with you</b>;
          },

          error: response => {
            // console.log({ response });
            setFormStatus("error");
            return <b>Could not save.</b>;
          },
        }
      );
    }
    // .then(() => {
    //   console.log("FORM SUBMITTED SUCCESSFULLY");
    //   setFormStatus("success");

    //   // TODO: Set loading state to false
    //   // TODO: Tick sign for success button
    //   // TODO: Reset Input fields
    //   // TODO: Show Success Message: We would contact you shortly
    // })
    // .catch(error => {
    //   console.log({ error });
    //   setFormStatus("error");
    //   // TODO: Set loading state to false
    //   // TODO: Error sign for error button
    //   // TODO: Show Error message: trouble completing process
    //   alert(error);
    // });
  };

  // console.log({ isValid });

  // data

  return (
    <Section name="contactMe" as="section">
      <ContactMeHeading>
        Let's start making your <SplashText variant="peach">perfect</SplashText>{" "}
        website
      </ContactMeHeading>
      <Form
        name="contact"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <input type="hidden" name="bot-field" />
        <FormText>Hey, my name is</FormText>
        <NameInput
          type="text"
          placeholder="Type here"
          {...register("name", { required: true })}
        />
        <FormText>and I’m looking for</FormText>
        <ServiceSelect
          placeholder="Select Dropdown"
          {...register("service", { required: true })}
        >
          <option value="">Select Service</option>
          <option value="front-end">Front End Web Development</option>
          <option value="full-stack">Full Stack Web Development</option>
          <option value="ui-ux">UI/UX Design</option>
        </ServiceSelect>
        {!isMobile && <br />}
        <FormText>Get in touch with me at</FormText>
        <EmailInput
          type="email"
          placeholder="Your email ID here"
          {...register("email", { required: true })}
        />
        {/* <Flex> */}
        <AsyncButton disabled={!isValid} type="submit" state={formStatus}>
          Let's Work
        </AsyncButton>
        {/* </Flex> */}
        {/* Desktop Only */}
        {!isMobile && (
          <ContactSectionFooter>
            <ContactSectionFooterText>
              Reach me out at&nbsp;
              <Link href={links.phone}>+917009028471</Link>,&nbsp;
              <Link href={links.email}>parmeetsasija@gmail.com</Link>
            </ContactSectionFooterText>
            <SocialIcons>
              {socialLinks.map(linkItem => (
                <IconButton
                  iconColor={colors.black}
                  bgColor="#999999"
                  href={linkItem.link}
                  target="_blank"
                  key={linkItem.name}
                  ariaLabel={linkItem.name}
                >
                  {linkItem.icon}
                </IconButton>
              ))}
            </SocialIcons>
          </ContactSectionFooter>
        )}
      </Form>
    </Section>
  );
};

export default ContactMeSection;

// states
//  - Loading
//    Show loader inside button
//    Disable inputs
//  - Success
//    Tick Sign on Button
//    Reset input fields
//    Show message: we would contact you shortly!
//  - Error
//    - Mark red
//    - Show error
//    1. Missing inputs
//        Mark the missing field as red
//        Show Message: Please ensure all the fields are filled
//    2. Incorrent regex or data
//        Mark the failed field as red and show
//        Show Message: Name is a required field
//        Show Message:
